import { type GranularityType } from '@tokenterminal/ui/Chart/Chart'
import {
  type ChartSerie,
  type ChartSerieTimeData,
} from '@tokenterminal/ui/Chart/useHighchartOptions'
import { getFirstMetricThreshold } from './firstMetricThreshold'

export function getPercentChangeSeries(
  serie: ChartSerie,
  serieData: ChartSerieTimeData,
  granularity: GranularityType,
): ChartSerieTimeData {
  const benchmarkValue =
    getFirstMetricThreshold(serie.metricId ?? '', granularity) || 0
  const firstValue =
    serieData.find(
      ([_, value]) => value !== null && value >= benchmarkValue,
    )?.[1] ?? 0

  return serieData.map(([timestamp, value]) => {
    if (value === null || value < benchmarkValue) {
      return [timestamp, null]
    }

    const percentage =
      firstValue === 0 ? 0 : ((value ?? 0) / firstValue - 1) * 100
    return [timestamp, percentage]
  })
}
