import { type CustomChart } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { Box } from "@tokenterminal/ui/Box"
import { type GranularityType } from "@tokenterminal/ui/Chart/Chart"
import { Stack } from "@tokenterminal/ui/Stack"
import { type Atom, useAtomValue } from "jotai"
import { Suspense } from "react"
import { BaseChart } from "./components/chart/BaseChart"
import { ChartHeading } from "./components/chart/ChartHeading"
import { Legend } from "./components/legend/Legend"
import { LegendPlaceholder } from "./components/legend/Placeholder"
import { useSeries } from "./hooks/useSeries"

export type ChartProps = {
  configAtom: Atom<CustomChart>
  showTitle: boolean
  showDescription: boolean
  isVisible: boolean
  onVisibilityChange?: (id: string, isVisible: boolean) => void
  showLegend?: boolean
  hideYAxisLabels?: boolean
  headingImageSlot?: React.ReactNode
}

export function Chart({
  configAtom,
  showTitle,
  showDescription,
  isVisible = true,
  onVisibilityChange,
  showLegend = true,
  hideYAxisLabels = false,
  headingImageSlot,
}: ChartProps) {
  const chartSerieSettings = useAtomValue(configAtom)

  const { series, seriesData } = useSeries(
    chartSerieSettings.configs,
    chartSerieSettings.zoom,
    chartSerieSettings.granularity as GranularityType
  )

  return (
    <Stack gap="3x" height="100%">
      {(showTitle || showDescription || showLegend) && (
        <Stack gap={showDescription || showTitle ? "3x" : "0"}>
          <ChartHeading
            showTitle={showTitle}
            showDescription={showDescription}
            title={chartSerieSettings.title ?? ""}
            description={chartSerieSettings.description ?? ""}
            headingImageSlot={headingImageSlot}
          />
          {showLegend && (
            <Suspense fallback={<LegendPlaceholder />}>
              <Legend
                onVisibilityChange={onVisibilityChange}
                chartSerieSettings={chartSerieSettings}
                series={series}
              />
            </Suspense>
          )}
        </Stack>
      )}
      <Box position="relative" minHeight={0} flexGrow={1}>
        <BaseChart
          isVisible={isVisible}
          chartSerieSettings={chartSerieSettings}
          series={series}
          seriesData={seriesData}
          hideYAxisLabels={hideYAxisLabels}
        />
      </Box>
    </Stack>
  )
}
